



































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component
export default class CosmopolAutocomplete extends Vue {
  @PropSync("selected", {
    required: false,
    default: null,
  })
  private _selected: unknown;
  @Prop({ required: true, default: () => [], type: Array })
  private items: [];
  @Prop({ required: false, default: null, type: [String, Number] })
  private itemText: string | number;
  @Prop({ required: false, default: null, type: [String, Number] })
  private itemValue: string | number;
  @Prop({ required: false, default: true, type: Boolean })
  private hideDetails: boolean;
  @Prop({ required: false, default: true, type: Boolean })
  private clearable: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private cacheItems: boolean;
  @Prop({ required: false, default: null, type: String })
  private label: string;
  @Prop({ required: false, default: null, type: String })
  private placeholder: string;
  @PropSync("searchInput", { required: false, default: null, type: String })
  private searchInput$: string;

  private searchTimeout: number = null;
  private id = uuidv4();

  onAutocompleteInput(item: string): void {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this._selected = item;
      this.$emit("on-change", item);
    }, 200);
  }
}
